import * as React from 'react';
import { ThemeContext } from '../context/themeContext';
const LinkedIn = (props) => {
  const { theme } = React.useContext(ThemeContext);
  const fill = theme === 'dark' ? 'white' : 'black';
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0C4.02944 0 0 4.02944 0 9V101C0 105.971 4.02944 110 9 110H101C105.971 110 110 105.971 110 101V9C110 4.02944 105.971 0 101 0H9ZM25.2551 35.9184C31.7642 35.9184 37.0408 30.6417 37.0408 24.1327C37.0408 17.6236 31.7642 12.3469 25.2551 12.3469C18.746 12.3469 13.4694 17.6236 13.4694 24.1327C13.4694 30.6417 18.746 35.9184 25.2551 35.9184ZM18.2142 41.5306C16.8335 41.5306 15.7142 42.6499 15.7142 44.0306V96.2755C15.7142 97.6562 16.8335 98.7755 18.2142 98.7755H31.1734C32.5541 98.7755 33.6734 97.6562 33.6734 96.2755V44.0306C33.6734 42.6499 32.5541 41.5306 31.1734 41.5306H18.2142ZM59.4897 65.1021V96.2755C59.4897 97.6562 58.3704 98.7755 56.9897 98.7755H44.0305C42.6498 98.7755 41.5305 97.6562 41.5305 96.2755V44.0306C41.5305 42.6499 42.6498 41.5306 44.0305 41.5306H56.9897C58.3704 41.5306 59.4897 42.6499 59.4897 44.0306V48.0505C62.048 43.5855 68.6212 40.4082 76.3265 40.4082C83.6597 40.4082 89.9674 43.286 92.7649 47.4128C93.9842 48.9666 95.0214 50.8457 95.8236 52.9697C96.9753 56.0192 97.6032 59.4687 97.6501 62.9921C97.652 63.0319 97.653 63.072 97.653 63.1123V63.4184V96.2755C97.653 97.6562 96.5337 98.7755 95.153 98.7755H81.0713C79.6906 98.7755 78.5713 97.6562 78.5713 96.2755V65.1021H78.4532C77.7309 60.0129 73.7873 56.1224 69.0306 56.1224C64.274 56.1224 60.3303 60.0129 59.6081 65.1021H59.4897Z"
        fill={fill}
      />
    </svg>
  );
};
export default LinkedIn;
