import * as React from 'react';
import { ThemeContext } from '../context/themeContext';

const BYU = (props) => {
  const { theme } = React.useContext(ThemeContext);
  const fill = theme === 'dark' ? 'white' : 'black';
  return (
    <svg
      width={149}
      height={42}
      viewBox="0 0 198 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.4212 21.091V11.516C22.4228 11.0843 22.3893 10.6532 22.3212 10.227C22.7648 10.2903 23.2121 10.3238 23.6602 10.327H30.8502C36.3502 10.327 39.5782 11.17 39.5782 16.179C39.5782 19.402 37.5942 22.328 31.0982 22.328H23.6602C23.2288 22.3327 22.7981 22.3661 22.3712 22.428C22.3712 22.428 22.4212 21.786 22.4212 21.091ZM22.4212 43.059V32.001C22.4228 31.5693 22.3893 31.1382 22.3212 30.712C22.7481 30.7739 23.1788 30.8073 23.6102 30.812H29.7102C34.5702 30.812 40.5202 30.812 40.5202 37.556C40.5202 42.565 36.2552 44.3 30.5532 44.3H23.6602C23.2288 44.3047 22.7981 44.3381 22.3712 44.4C22.3712 44.4 22.4212 43.704 22.4212 43.059ZM4.96618 4.42898V50.448C4.96618 51.936 5.06618 52.481 3.97418 53.126C2.78293 53.7222 1.48519 54.076 0.156175 54.167H0.106176C-0.0428238 54.217 -0.0428246 54.861 0.156175 54.861H37.3982C53.1182 54.861 59.7132 48.613 59.7132 38.893C59.7132 31.7023 56.3908 27.289 49.7462 25.653C49.6462 25.603 49.6462 25.453 49.7462 25.453C53.9612 24.114 57.8292 21.089 57.8292 13.601C57.8292 3.43498 50.9862 0.0139771 36.0102 0.0139771H0.156175C0.0071754 0.0139771 -0.0438238 0.658977 0.106176 0.707977H0.156175C1.48399 0.805979 2.78038 1.15945 3.97418 1.74898C5.01418 2.39598 4.96618 2.89198 4.96618 4.42898Z"
        fill={fill}
      />
      <path
        d="M192.561 4.42899C192.561 2.94099 192.461 2.39599 193.553 1.75099C194.745 1.1548 196.042 0.800965 197.371 0.709991H197.421C197.57 0.659991 197.57 0.0159912 197.371 0.0159912H169.9C169.751 0.0159912 169.7 0.660991 169.85 0.709991H169.9C171.159 0.748947 172.388 1.10715 173.47 1.75099C174.511 2.39599 174.462 2.94099 174.462 4.42899V32.1C174.462 38.4 170.842 43.258 163.652 43.258C156.462 43.258 152.842 38.398 152.842 32.1V4.42899C152.842 2.94099 152.742 2.39599 153.834 1.75099C154.929 1.13633 156.15 0.780254 157.404 0.709991H157.454C157.603 0.659991 157.603 0.0159912 157.404 0.0159912H131.467C131.267 0.0159912 131.318 0.660991 131.467 0.709991H131.517C132.292 0.650751 133.064 0.862868 133.699 1.30999C134.542 2.05399 134.691 2.89699 134.691 4.43399V31.604C134.691 44.696 141.881 55.853 163.651 55.853C185.421 55.853 192.611 44.745 192.611 31.604V4.42899H192.561Z"
        fill={fill}
      />
      <path
        d="M109.351 54.862C109.5 54.862 109.551 54.217 109.401 54.168H109.351C108.023 54.07 106.727 53.7165 105.533 53.127C104.492 52.482 104.541 51.937 104.541 50.449V32.993L123.534 4.777C124.235 3.62834 125.104 2.59172 126.113 1.702C126.262 1.602 126.613 1.355 126.807 1.202C127.419 0.834635 128.128 0.66038 128.84 0.701996H128.89C129.039 0.701996 129.138 0.00799561 128.94 0.00799561H103.302C103.102 0.00799561 103.153 0.701996 103.302 0.701996H103.352C104.592 0.701996 106.129 0.701995 105.881 2.487C105.732 4.223 98.3433 14.887 95.7153 18.951C95.3013 19.5236 95.0294 20.1865 94.9223 20.885C94.7734 20.2059 94.4865 19.5646 94.0793 19.001C90.4093 13.546 84.1613 4.719 83.9633 2.537C83.8143 0.701995 85.2523 0.751996 86.4923 0.751996H86.5423C86.7423 0.751996 86.7903 0.0579956 86.5923 0.0579956H59.7133C59.5643 0.0579956 59.5643 0.657996 59.7133 0.751996H59.7633C60.8441 0.740122 61.9132 0.977606 62.8873 1.446C63.0363 1.546 63.4323 1.793 63.5813 1.892C64.5384 2.73515 65.3583 3.72233 66.0113 4.818L85.0043 32.886V50.486C85.0043 51.974 85.1043 52.519 84.0123 53.164C82.8211 53.7602 81.5233 54.114 80.1943 54.205H80.1443C79.9953 54.255 79.9953 54.899 80.1943 54.899H109.353L109.351 54.862Z"
        fill={fill}
      />
      <path
        d="M22.6599 21.083V11.508C22.6615 11.0763 22.6281 10.6452 22.5599 10.219C23.0035 10.2823 23.4509 10.3158 23.8989 10.319H31.0889C36.5889 10.319 39.8169 11.162 39.8169 16.171C39.8169 19.394 37.8329 22.32 31.3369 22.32H23.8989C23.4675 22.3247 23.0369 22.3581 22.6099 22.42C22.6099 22.42 22.6599 21.778 22.6599 21.083ZM22.6599 43.051V31.993C22.6615 31.5613 22.6281 31.1302 22.5599 30.704C22.9869 30.7659 23.4175 30.7993 23.8489 30.804H29.9489C34.8089 30.804 40.7589 30.804 40.7589 37.548C40.7589 42.557 36.4939 44.292 30.7919 44.292H23.8989C23.4675 44.2967 23.0369 44.3301 22.6099 44.392C22.6099 44.392 22.6599 43.696 22.6599 43.051ZM5.20495 4.42098V50.44C5.20495 51.928 5.30495 52.473 4.21295 53.118C3.0217 53.7142 1.72396 54.068 0.394945 54.159H0.344946C0.195946 54.209 0.195945 54.853 0.394945 54.853H37.6369C53.3569 54.853 59.9519 48.605 59.9519 38.885C59.9519 31.6943 56.6296 27.281 49.9849 25.645C49.8849 25.595 49.8849 25.445 49.9849 25.445C54.1999 24.106 58.0679 21.081 58.0679 13.593C58.0679 3.42698 51.2249 0.00598145 36.2489 0.00598145H0.394945C0.245945 0.00598145 0.194946 0.650981 0.344946 0.699981H0.394945C1.72276 0.797983 3.01915 1.15145 4.21295 1.74098C5.25295 2.38798 5.20495 2.88398 5.20495 4.42098Z"
        fill={fill}
      />
      <path
        d="M192.8 4.421C192.8 2.933 192.7 2.388 193.792 1.743C194.983 1.1468 196.281 0.792969 197.61 0.701996H197.66C197.809 0.651996 197.809 0.00799561 197.61 0.00799561H170.139C169.99 0.00799561 169.939 0.652996 170.089 0.701996H170.139C171.398 0.740951 172.626 1.09916 173.709 1.743C174.75 2.388 174.701 2.933 174.701 4.421V32.092C174.701 38.392 171.081 43.25 163.891 43.25C156.701 43.25 153.081 38.39 153.081 32.092V4.421C153.081 2.933 152.981 2.388 154.073 1.743C155.168 1.12834 156.389 0.772258 157.643 0.701996H157.693C157.842 0.651996 157.842 0.00799561 157.643 0.00799561H131.706C131.506 0.00799561 131.557 0.652996 131.706 0.701996H131.756C132.531 0.642756 133.302 0.854873 133.938 1.302C134.781 2.046 134.93 2.889 134.93 4.426V31.596C134.93 44.688 142.12 55.845 163.89 55.845C185.66 55.845 192.85 44.737 192.85 31.596V4.421H192.8Z"
        fill={fill}
      />
      <path
        d="M109.59 54.854C109.739 54.854 109.79 54.209 109.64 54.16H109.59C108.262 54.062 106.966 53.7085 105.772 53.119C104.731 52.474 104.78 51.929 104.78 50.441V32.985L123.773 4.769C124.474 3.62035 125.343 2.58372 126.352 1.694C126.501 1.594 126.852 1.347 127.046 1.194C127.658 0.826639 128.367 0.652385 129.079 0.694H129.129C129.278 0.694 129.377 0 129.179 0H103.541C103.341 0 103.392 0.694 103.541 0.694H103.591C104.831 0.694 106.368 0.694 106.12 2.479C105.971 4.215 98.5821 14.879 95.9541 18.943C95.54 19.5156 95.2682 20.1785 95.1611 20.877C95.0122 20.1979 94.7252 19.5566 94.3181 18.993C90.6481 13.538 84.4001 4.711 84.2021 2.529C84.0531 0.694 85.4911 0.744 86.7311 0.744H86.7811C86.9811 0.744 87.0291 0.05 86.8311 0.05H59.9521C59.8031 0.05 59.8031 0.65 59.9521 0.744H60.0021C61.0829 0.732126 62.1519 0.96961 63.1261 1.438C63.2751 1.538 63.6711 1.785 63.8201 1.884C64.7772 2.72716 65.597 3.71434 66.2501 4.81L85.2431 32.878V50.478C85.2431 51.966 85.3431 52.511 84.2511 53.156C83.0598 53.7522 81.7621 54.1061 80.4331 54.197H80.3831C80.2341 54.247 80.2341 54.891 80.4331 54.891H109.592L109.59 54.854Z"
        fill={fill}
      />
    </svg>
  );
};
export default BYU;
